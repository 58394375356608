import React, { useMemo, useState, useEffect, useCallback } from 'react';
import { get, has } from 'lodash-es';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import {
  Form,
  Field,
  SubmitButton,
  Address,
  CopyToClipboard,
  DateFormat,
  Price,
  Tooltip,
  ProductName,
  VariantPrice,
} from '@bottomless/common/components';
import { useToggle } from '@bottomless/common/hooks';
import { PilotCategories } from '@bottomless/common/constants';
import { Badge, Button, ButtonGroup, Card, Col, Collapse, Modal, ModalBody, ModalHeader, Row } from 'reactstrap';
import { Link } from 'react-router-dom';
import { EmailEditor } from '../../../../../components/EmailEditor/EmailEditor';
import { AddMonthsForm, CreateScaleShipmentForm, CreditsForm, GrindForm, MagicLinkForm, ReOpenForm } from '../forms';
import { NeedsSupportForm } from '../../../../../components/NeedsSupport/NeedsSupport';
import { ScaleStatus } from '../../../../../components/ScaleStatus';
import { ManageScaleForm } from '../forms/ManageScaleForm';
import { UpdateEmailForm } from '../forms/UpdateEmailForm';
import { PauseForm } from '../forms/PauseForm';
import { ReferralForm } from '../forms/ReferralForm';
import { DumbPeriodForm } from '../forms/DumbPeriodForm';
import { UpdateSocialMediaForm } from '../forms/UpdateSocialMediaForm';
import { AttributeInfluencerForm } from '../forms/AttributeInfluencerForm';
import { UpdateAddressForm } from '../forms/UpdateAddressForm';
import { UpdateProductForm } from '../forms/UpdateProductForm';
import { UpdatePhoneForm } from '../forms/UpdatePhoneForm';
import { SocialLinks } from './SocialLinks';
import { getVariantSelectLabel } from '@bottomless/common/utils';

const StatusBadge = ({ status }) => {
  const color = useMemo(() => {
    switch (status) {
      case 'active':
      case 'guest':
        return 'success';
      case 'closed':
        return 'danger';
      case 'offboarding':
        return 'warning';
      default:
        return 'info';
    }
  }, [status]);

  return <Badge color={color}>{status}</Badge>;
};

StatusBadge.propTypes = {
  status: PropTypes.string.isRequired,
};

const PricingRule = ({ user, pricingRules, updatePricingRule, pricingRulesLoading, addToast }) => {
  const [isOpen, toggle] = useToggle();
  const [activeRule, setActiveRule] = useState({});

  useEffect(() => {
    setCurRule(user.pricing_rule._id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const setCurRule = ruleID => {
    pricingRules.map(rule => {
      if (rule._id === ruleID) {
        setActiveRule(rule);
        return;
      }
    });
  };

  const pricingRuleOptions = pricingRulesLoading
    ? {}
    : pricingRules.reduce((all, rule) => ({ ...all, [rule._id]: `${rule._id} -- ${rule.monthly_fee}` }), {});

  return (
    <>
      {isOpen && (
        <Modal isOpen={isOpen}>
          <>
            <ModalHeader toggle={toggle}>
              <span>
                Change pricing rule for {user.first_name} {user.last_name}
              </span>
            </ModalHeader>
            <ModalBody>
              <Form
                initialValues={{
                  pricing_rule: user.pricing_rule._id,
                }}
                onSubmit={data => updatePricingRule(user._id, data)}
                onSuccess={() => {
                  toggle();
                  addToast(`Pricing rule udpated`, 'success');
                }}
              >
                {({ isSubmitting }) => (
                  <>
                    <Field
                      name="pricing_rule"
                      label="Pricing Rule"
                      type="select"
                      options={pricingRuleOptions}
                      onChange={e => {
                        setCurRule(e.target.value);
                      }}
                    />
                    <SubmitButton color="dark" isSubmitting={isSubmitting} className="mt-2">
                      Save
                    </SubmitButton>
                  </>
                )}
              </Form>
              <div>{activeRule.default ? 'DEFAULT' : null}</div>
              <div>
                Monthly Fee: <Price value={activeRule.monthly_fee} cents />
              </div>
              <div>Batch size: {activeRule.batch_size}</div>
              <div>Free Trial Length: {activeRule.free_trial_length}</div>
              <div>
                Signup Fee: <Price value={activeRule.signup_fee} cents />
              </div>
              <div>Desc: {activeRule.description}</div>
            </ModalBody>
          </>
        </Modal>
      )}

      <div>
        <Button
          onClick={() => toggle()}
          color="link"
          className="btn-selectable"
          style={{ backgroundColor: 'white', color: 'blue', border: '0px', padding: '0px', fontSize: '14px' }}
        >
          {user.pricing_rule._id}
        </Button>
      </div>

      <Tooltip content={user.pricing_rule.description} id={user._id}>
        <div className="mr-1">
          Signup: <Price value={user.pricing_rule.signup_fee} cents />
        </div>
        <div>
          <span>Monthly: </span>
          <Price value={user.pricing_rule.monthly_fee} cents />
        </div>
      </Tooltip>
    </>
  );
};

PricingRule.propTypes = {
  user: PropTypes.shape({
    _id: PropTypes.string.isRequired,
    first_name: PropTypes.string,
    last_name: PropTypes.string,
    pricing_rule: PropTypes.shape({
      _id: PropTypes.string.isRequired,
      free_shipping: PropTypes.bool,
      signup_fee: PropTypes.string,
      monthly_fee: PropTypes.number.isRequired,
      batch_size: PropTypes.number.isRequired,
      free_trial_length: PropTypes.number.isRequired,
      description: PropTypes.string.isRequired,
    }),
  }),
  pricingRulesLoading: PropTypes.bool,
  pricingRules: PropTypes.array.isRequired,
  updatePricingRule: PropTypes.func.isRequired,
  addToast: PropTypes.func.isRequired,
};

const CREDIT_GRANTED = 'granted';
const CREDIT_REDEEMED = 'redeemed';

const FORM_ADD_MONTHS = 'add_months';
const FORM_CREDITS = 'credits';
const DUMB_PERIODS = 'dumb_period';
const FORM_REPLACE_SCALE = 'replace_scale';
const FORM_MAGIC_LINK = 'magic_link';
const MODAL_EMAIL = 'email';
const FORM_GRIND = 'grind';
const FORM_UPDATE_EMAIL = 'update_email';
const FORM_UPDATE_PHONE = 'update_phone';
const FORM_UPDATE_ADDRESS = 'update_address';
const FORM_UPDATE_PRODUCT = 'update_product';
const FORM_PAUSE = 'pause';
const FORM_REFERRAL = 'referral';
const FORM_SOCIAL_MEDIA = 'social_media';
const ATTRIBUTE_INFLUENCER = 'attribute_influencer';
const FORM_RE_OPEN_ACCOUNT = 're_open_account';
const NEEDS_SUPPORT = 'needs_support';
const MANAGE_SCALE = 'manage_scale';
const PETS_INVITE = 'pets_invite';
const HAF_INVITE = 'haf_invite';

const ProductVariant = ({ productVariantSchema, personalized, grind, user }) => {
  const variant = has(productVariantSchema, 'product.variants')
    ? productVariantSchema.product.variants.find(v => v._id === productVariantSchema.variant)
    : null;

  return (
    <>
      <div className="text-secondary text-sm">{get(productVariantSchema, 'product.vendor_name', '---')}</div>
      {has(productVariantSchema, 'product.name') ? (
        <ProductName
          product={productVariantSchema}
          personalized={personalized}
          grind={grind}
          quantity={user.quantity ? user.quantity : undefined}
          productVariant={productVariantSchema}
        />
      ) : (
        '---'
      )}
      <div className="text-primary text-sm">
        {variant && (
          <>
            {variant.size}oz -{' '}
            {has(personalized, 'price_type') ? (
              get(personalized, 'price_type').replace(/^\w/, c => c.toUpperCase())
            ) : (
              <VariantPrice user={user} variant={variant} />
            )}
          </>
        )}
      </div>
      {user.lineItems?.length > 0 && <div className="text-secondary">+{user.lineItems.length} more</div>}
    </>
  );
};

ProductVariant.propTypes = {
  productVariantSchema: PropTypes.shape({
    variant: PropTypes.string.isRequired,
    product: PropTypes.shape({
      name: PropTypes.string.isRequired,
      vendor_name: PropTypes.string.isRequired,
      variants: PropTypes.array.isRequired,
    }).isRequired,
  }),
  personalized: PropTypes.shape({
    price_type: PropTypes.string,
  }),
  grind: PropTypes.string.isRequired,
  user: PropTypes.any,
};

const ProductSchema = Yup.object().shape({
  product: Yup.string().required('This field is required'),
  variant: Yup.string().required('This field is required'),
});

const createSelectOptions = (data, label, inputLabel = item => item.name) =>
  !data
    ? {}
    : data.reduce((all, { _id, ...item }) => ({ ...all, [_id]: inputLabel(item) }), label ? { null: label } : {});

export const User = ({
  pricingRules,
  user: initialUser,
  addToast,
  addCredits,
  addMonths,
  closeAccount,
  hibernateAccount,
  updateEmail,
  updateSocialMedia,
  attributeInfluencer,
  createScaleShipment,
  createMagicLink,
  giftOrderEmail,
  updateGrind,
  updatePricingRule,
  options,
  pauseAccount,
  searchUser,
  setReferrer,
  updateDumbSubscription,
  reOpenAccount,
  setNeedsSupport,
  disconnectScale,
  getScale,
  updateAddress,
  updateUserProduct,
  products,
  updatePhone,
  getUserLastOrder,
  sendPilotInvite,
  scaleDevices,
}) => {
  const [user, setUser] = useState(initialUser);
  const [isCollapsed, collapse] = useToggle();
  const [isOpen, toggle] = useToggle();
  const [type, setType] = useState();
  const [magicLink, setMagicLink] = useState(null);
  const [petProduct, setPetProduct] = useState();
  const [petProducts, setPetProducts] = useState();
  const [hafProduct, setHafProduct] = useState();
  const [hafProducts, setHafProducts] = useState();

  const [isLoadingLastOrder, setLoadingLastOrder] = useState(false);
  const [userLastOrder, setUserLastOrder] = useState(null);

  useEffect(() => {
    setUser(initialUser);
  }, [initialUser, setUser]);

  useEffect(() => {
    const filteredPetProducts = products?.filter(p => p.category.slug === 'pet-food');
    setPetProducts(filteredPetProducts);
  }, [products]);

  useEffect(() => {
    const filteredHafProducts = products?.filter(p => p.category.slug === 'health-and-fitness');
    setHafProducts(filteredHafProducts);
  }, [products]);

  const selectPetProduct = useCallback(
    id => {
      const product = products.find(p => p._id === id);

      setPetProduct(product);
    },
    [products]
  );

  const selectHafProduct = useCallback(
    id => {
      const product = products.find(p => p._id === id);

      setHafProduct(product);
    },
    [products]
  );

  const credits = useMemo(() => {
    const redeemed = user.credits?.filter(credit => credit.status === CREDIT_REDEEMED).length || 0;
    const granted = user.credits?.filter(credit => credit.status === CREDIT_GRANTED).length || 0;
    const total = user.credits?.length || 0;

    return { redeemed, granted, total };
  }, [user]);

  const social_media = useMemo(() => {
    const credits = user.social_media?.credits_granted;
    const orders = user.social_media?.total_order;
    const isInfluencer = user.social_media?.micro_influencer;
    return { credits, orders, isInfluencer };
  }, [user]);

  const pendingScaleShipments = useMemo(
    () => (user.scale_shipments || []).filter(({ status }) => !['arrived', 'canceled'].includes(status)),
    [user]
  );

  const openForm = type => {
    setType(type);
    toggle();
  };

  const onEmailButtonClick = useCallback(async () => {
    setLoadingLastOrder(true);
    const { payload } = await getUserLastOrder(user._id);
    setUserLastOrder(payload);
    openForm(MODAL_EMAIL);
    setLoadingLastOrder(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getUserLastOrder, setUserLastOrder, openForm]);

  const cardProps = useMemo(
    () =>
      user.status === 'onboarding_paid' && pendingScaleShipments.length === 0
        ? {
            color: 'warning',
          }
        : {},
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [user]
  );

  return (
    <Card className="mb-2" body {...cardProps}>
      <div onClick={collapse} className="d-flex align-items-center cursor-pointer">
        <div className="d-flex flex-row w-100">
          <div>
            <StatusBadge status={user.status} />
            {user.hibernate && (
              <Badge className="ml-2" color="danger">
                hibernating
              </Badge>
            )}
            {user.support && user.support.order && user.support.order.needs_support && (
              <Badge className="ml-2" color="danger">
                support needed
              </Badge>
            )}
            {user.trial && (
              <Badge color="info" className="ml-1">
                Trial
              </Badge>
            )}
            {user.paused && (
              <Badge color="warning" className="ml-1">
                Paused
              </Badge>
            )}
            {user.dumb_period && (
              <Badge color="warning" className="ml-1">
                dumb_period: {user.dumb_period}
              </Badge>
            )}
            {user.vendor_id && (
              <Badge color="info" className="ml-1">
                Vendor: {user.vendor_id.name}
              </Badge>
            )}
            {user.shopifySubscriptionContractId && (
              <Badge className="ml-2" color="dark">
                Simple Subscription
              </Badge>
            )}
            <div className="text-primary mt-2">
              <CopyToClipboard text={user._id} useIcon />
              <span className="ml-2">{user._id}</span>
            </div>
          </div>
          <div className="ml-4">
            <span>
              <Link to={`/admin/users/${user._id}`}>
                <span className="text-primary">
                  {user.first_name} {user.last_name}
                </span>
              </Link>
            </span>
            <span className="d-block text-primary mb-2">
              <a
                href={`https://app.frontapp.com/compose?mailto=mailto:${user.local.email}`}
                rel="noopener noreferrer"
                target="_blank"
                className="text-primary"
              >
                {user.local.email}
              </a>
            </span>
          </div>
          <div className="ml-auto text-right user-select-none">
            <div>
              {user.scale_status ? (
                <ScaleStatus status={user} withHeader={false} />
              ) : (
                <span className="d-block badge badge-warning mb-3">Missing scale data</span>
              )}
            </div>
            <kbd className="text-sm mr-1">id: {get(user, 'base_id', '---')}</kbd>
            <kbd className="text-sm mr-1">tare: {get(user, 'base_tare', '---')}</kbd>
            <kbd className="text-sm">calibration: {get(user, 'base_calibration', '---')}</kbd>
          </div>
        </div>
      </div>
      <Collapse isOpen={isCollapsed}>
        <div className="pt-4">
          <div className="border-top pt-4">
            <Row className="justify-content-between">
              <Col xs="6" md="3" className="d-flex flex-column justify-content-between align-items-start">
                <div>
                  {user.name && <div>Scale: {user.name}</div>}

                  <div>
                    <a
                      href={`https://app.frontapp.com/compose?mailto=mailto:${user.phone}`}
                      rel="noopener noreferrer"
                      target="_blank"
                      className="text-primary"
                    >
                      {user.phone}
                    </a>
                  </div>
                  <div>
                    {user.first_name} {user.last_name}
                    {user.verifiedAddress ? (
                      <Address address={user.verifiedAddress} />
                    ) : (
                      <span className="d-block text-warning">Missing address</span>
                    )}
                  </div>
                  <div className="mt-3">
                    {user.signupDate && (
                      <div>
                        <span className="mr-2">Signup Date:</span>
                        <DateFormat date={user.signupDate} />
                      </div>
                    )}
                    {user.pausedUntil && (
                      <div>
                        <span className="mr-2">Paused until:</span>
                        <DateFormat date={user.pausedUntil} fromZeroed />
                      </div>
                    )}
                    <div>
                      <span className="mr-2">Paid until:</span>
                      {user.paidUntil ? <DateFormat date={user.paidUntil} /> : '---'}
                    </div>
                    <div>
                      <span className="mr-2">Landing page:</span>
                      {get(user, 'landing_page', '---')}
                    </div>
                  </div>
                  <div className="mt-3 text-danger">
                    <div>
                      <span className="mr-2">Card type:</span>
                      {get(user, 'stripe_brand', '---')}
                    </div>
                    <div>
                      <span className="mr-2">Last 4 digits:</span>
                      {get(user, 'stripe_last_four', '---')}
                    </div>
                  </div>
                  <SocialLinks user={user} />
                </div>
                <Button onClick={() => openForm(FORM_SOCIAL_MEDIA)} size="sm" color="info" className="my-2 mr-2">
                  Social media
                </Button>
                {user.social_media?.micro_influencer && (
                  <Button onClick={() => openForm(ATTRIBUTE_INFLUENCER)} size="sm" color="info">
                    Attribute
                  </Button>
                )}
              </Col>
              <Col xs="6" md="3">
                {user.pricing_rule && (
                  <div>
                    <span className="mr-2">Pricing rule:</span>
                    <PricingRule
                      user={user}
                      pricingRules={Object.values(pricingRules)}
                      updatePricingRule={updatePricingRule}
                      addToast={addToast}
                    />
                  </div>
                )}

                <Link to={`/referral/${user.referral_id}`} target="_blank" className="text-primary">
                  {user.referral_id}
                </Link>

                {credits && (
                  <>
                    <div>
                      <b>Credits:</b>
                    </div>
                    <div>
                      <span>Redeemed: {credits.redeemed}</span>
                    </div>
                    <div>
                      <span>Granted: {credits.granted}</span>
                    </div>
                    <div>
                      <span>Total: {credits.total}</span>
                    </div>
                  </>
                )}
                {social_media && social_media.isInfluencer && (
                  <>
                    <div>
                      <b>Social Media:</b>
                    </div>
                    <div>
                      <span>Total Order: {social_media.orders}</span>
                    </div>
                    <div>
                      <span>Credits Granted: {social_media.credits}</span>
                    </div>
                  </>
                )}
              </Col>
              <Col xs="6" md="3">
                <ProductVariant
                  productVariantSchema={user.product}
                  personalized={user.personalized}
                  grind={user.grind?.name}
                  user={user}
                />
              </Col>
              <Col xs="6" md="2">
                <ButtonGroup vertical>
                  {user.grind && (
                    <Button onClick={() => openForm(FORM_GRIND)} size="sm" className="mb-1" color="info">
                      Grind
                    </Button>
                  )}
                  <Button onClick={() => openForm(FORM_ADD_MONTHS)} size="sm" className="mb-1" color="info">
                    Add months
                  </Button>

                  <Button onClick={() => openForm(FORM_CREDITS)} size="sm" className="mb-1" color="info">
                    Credits
                  </Button>
                  <Button onClick={() => openForm(FORM_REPLACE_SCALE)} size="sm" className="mb-1" color="info">
                    Scale shipment
                  </Button>
                  <Button onClick={() => openForm(FORM_MAGIC_LINK)} size="sm" className="mb-1" color="info">
                    Magic link
                  </Button>
                  <Button onClick={onEmailButtonClick} size="sm" color="info" className="mb-1">
                    {isLoadingLastOrder ? 'Loading...' : 'Email'}
                  </Button>

                  <Button onClick={() => openForm(FORM_UPDATE_EMAIL)} size="sm" color="success" className="mb-1">
                    Update Email
                  </Button>
                  <Button onClick={() => openForm(FORM_UPDATE_PHONE)} size="sm" color="success" className="mb-1">
                    Update Phone
                  </Button>
                  <Button onClick={() => openForm(FORM_UPDATE_ADDRESS)} size="sm" color="success" className="mb-1">
                    Update Address
                  </Button>
                  {user.product && user.product.product && !user.product.product.rotating && (
                    <Button onClick={() => openForm(FORM_UPDATE_PRODUCT)} size="sm" color="success" className="mb-1">
                      Update Product
                    </Button>
                  )}

                  <Button onClick={() => openForm(FORM_PAUSE)} size="sm" color="success" className="mb-1">
                    Pause account
                  </Button>

                  <Button onClick={() => openForm(FORM_REFERRAL)} size="sm" color="success" className="mb-1">
                    Set referral
                  </Button>

                  <Button onClick={() => openForm(NEEDS_SUPPORT)} size="sm" color="success" className="mb-1">
                    Needs support
                  </Button>

                  <Button onClick={() => openForm(MANAGE_SCALE)} size="sm" color="success" className="mb-1">
                    Manage scale
                  </Button>

                  {user.landing_page === '/gift' && (
                    <Button onClick={() => giftOrderEmail({ userId: user._id })} size="sm" color="success">
                      Gift order email
                    </Button>
                  )}

                  <Button onClick={() => openForm(DUMB_PERIODS)} size="sm" className="mb-1" color="info">
                    Adjust Dumb Period
                  </Button>

                  <Button onClick={() => openForm(PETS_INVITE)} size="sm" className="mb-2" color="info">
                    Pets Invite
                  </Button>

                  <Button onClick={() => openForm(HAF_INVITE)} size="sm" className="mb-2" color="info">
                    Health and Fitness Invite
                  </Button>

                  {(user.status === 'offboarding' || user.status === 'closed') && (
                    <Button
                      onClick={() => openForm(FORM_RE_OPEN_ACCOUNT)}
                      size="sm"
                      className="mb-1 mt-3"
                      color="danger"
                    >
                      Re-Open Account
                    </Button>
                  )}

                  {user.status !== 'closed' && user.status !== 'offboarding' && !user.hibernate && (
                    <Button
                      onClick={async () => {
                        const { error } = await hibernateAccount(user._id, { hibernate: true });
                        if (error) {
                          addToast('Oops something went wrong');
                        } else {
                          addToast('Account hibernated');
                        }
                      }}
                      size="sm"
                      color="danger"
                      className="mt-3"
                    >
                      Hibernate User
                    </Button>
                  )}
                  {user.status !== 'closed' && user.status !== 'offboarding' && user.hibernate && (
                    <Button
                      onClick={async () => {
                        const { error } = await hibernateAccount(user._id, { hibernate: false });
                        if (error) {
                          addToast('Oops something went wrong');
                        } else {
                          addToast('Account un-hibernated');
                        }
                      }}
                      size="sm"
                      color="danger"
                      className="mt-3"
                    >
                      Un-hibernate User
                    </Button>
                  )}

                  {user.status !== 'closed' && (
                    <Button
                      onClick={() => {
                        closeAccount(user._id);
                        addToast('Account closed');
                      }}
                      size="sm"
                      color="danger"
                      className="mt-3"
                    >
                      Close Account
                    </Button>
                  )}
                </ButtonGroup>
              </Col>
            </Row>

            {pendingScaleShipments && pendingScaleShipments.length > 0 && (
              <Row className="mt-2">
                <Col xs="12">
                  <span className="mb-2">Scale shipments in transit</span>
                  <ol>
                    {pendingScaleShipments.map(shipment => (
                      <li key={shipment._id}>
                        <Link to={`/admin/scale-shipments/all?search=${shipment._id}`} className="text-primary">
                          {shipment.type} / {shipment.status} / <DateFormat date={shipment.timestamp} withTime />
                        </Link>
                      </li>
                    ))}
                  </ol>
                </Col>
              </Row>
            )}
          </div>
        </div>
      </Collapse>
      {isOpen && (
        <Modal isOpen={isOpen} size={`${[MODAL_EMAIL].includes(type) ? 'lg' : ''}`}>
          {type === FORM_CREDITS && (
            <>
              <ModalHeader toggle={toggle}>
                <span>
                  Add credits {user.first_name} {user.last_name}
                </span>
              </ModalHeader>
              <ModalBody>
                <CreditsForm
                  onSubmit={data => addCredits(user._id, data)}
                  onSubmitSuccess={() => {
                    toggle();
                    addToast('Credit granted', 'success');
                  }}
                />
              </ModalBody>
            </>
          )}

          {type === FORM_REPLACE_SCALE && (
            <>
              <ModalHeader toggle={toggle}>
                <span>Create scale shipment {get(user, 'local.email')}</span>
              </ModalHeader>
              <ModalBody>
                <CreateScaleShipmentForm
                  onSubmit={data => createScaleShipment(user._id, data)}
                  onSubmitSuccess={e => {
                    if (e.status === 404 || e.status === 500) {
                      if (e.response.message) {
                        addToast(e.response.message, 'danger');
                      } else {
                        addToast('An error occurred', 'danger');
                      }
                    } else {
                      toggle();
                      addToast('Scale shipment created', 'success');
                    }
                  }}
                  scaleDevices={scaleDevices}
                  user={user}
                />
              </ModalBody>
            </>
          )}

          {type === FORM_ADD_MONTHS && (
            <>
              <ModalHeader toggle={toggle}>
                <span>
                  Add months {user.first_name} {user.last_name}
                </span>
              </ModalHeader>
              <ModalBody>
                <AddMonthsForm
                  onSubmit={data => addMonths(user._id, data)}
                  onSubmitSuccess={() => {
                    toggle();
                    addToast('Months added', 'success');
                  }}
                />
              </ModalBody>
            </>
          )}

          {type === FORM_MAGIC_LINK && (
            <>
              <ModalHeader toggle={toggle}>
                <span>Magic link</span>
              </ModalHeader>
              <ModalBody>
                {!magicLink && (
                  <MagicLinkForm
                    onSubmit={data => createMagicLink(user._id, data)}
                    onSubmitSuccess={data => {
                      addToast('Magic link created');
                      setMagicLink(data);
                    }}
                    showForceBottomless={
                      !!user.vendor_id?.shopifyManifest.accountPageId && user.vendor_id?.shopifyManifest.storefrontUrl
                    }
                  />
                )}

                {magicLink && (
                  <>
                    <div className="d-flex flex-column text-sm mb-4">
                      <div className="mb-2">
                        <code>{magicLink.url}</code>
                      </div>
                      <div>
                        <CopyToClipboard text={magicLink.url} />
                      </div>
                    </div>

                    {magicLink.short && (
                      <div className="d-flex flex-column text-sm">
                        <div className="mb-2">
                          <code>{magicLink.short.url}</code>
                        </div>
                        <div>
                          <CopyToClipboard text={magicLink.short.url} />
                        </div>
                      </div>
                    )}

                    <div className="mt-3">
                      <Button onClick={() => setMagicLink(null)} color="success">
                        Create new
                      </Button>
                    </div>
                  </>
                )}
              </ModalBody>
            </>
          )}

          {type === MODAL_EMAIL && (
            <>
              <ModalHeader toggle={toggle}>Send notification</ModalHeader>
              <ModalBody>
                <EmailEditor
                  context={{ userId: user._id, lastOrderId: userLastOrder?._id }}
                  onSuccess={() => toggle()}
                />
              </ModalBody>
            </>
          )}

          {type === FORM_GRIND && (
            <>
              <ModalHeader toggle={toggle}>Grind</ModalHeader>
              <ModalBody>
                <GrindForm
                  user={user}
                  grinds={options.grind}
                  onSubmit={data => updateGrind(user._id, data)}
                  onSubmitSuccess={() => {
                    toggle();
                    addToast('Grind updated', 'success');
                  }}
                />
              </ModalBody>
            </>
          )}

          {type === FORM_UPDATE_EMAIL && (
            <>
              <ModalHeader toggle={toggle}>Update Email</ModalHeader>
              <ModalBody>
                <UpdateEmailForm
                  user={user}
                  onSubmit={data => updateEmail(user._id, data)}
                  onSubmitSuccess={() => {
                    toggle();
                    addToast('Email updated', 'success');
                  }}
                />
              </ModalBody>
            </>
          )}

          {type === FORM_UPDATE_PHONE && (
            <>
              <ModalHeader toggle={toggle}>Update Phone</ModalHeader>
              <ModalBody>
                <UpdatePhoneForm
                  user={user}
                  onSubmit={data => updatePhone(user._id, data)}
                  onSubmitSuccess={e => {
                    if (e && e._id === user._id) {
                      setUser(e);
                      toggle();
                      addToast('Phone number updated', 'success');
                    } else {
                      addToast('Could not update phone number', 'danger');
                    }
                  }}
                />
              </ModalBody>
            </>
          )}

          {type === FORM_UPDATE_ADDRESS && (
            <>
              <ModalHeader toggle={toggle}>Update Address</ModalHeader>
              <ModalBody>
                <UpdateAddressForm
                  user={user}
                  onSubmit={data => updateAddress(user._id, data)}
                  onSubmitSuccess={() => {
                    toggle();
                    addToast('Address updated', 'success');
                  }}
                />
              </ModalBody>
            </>
          )}

          {type === FORM_UPDATE_PRODUCT && (
            <>
              <ModalHeader toggle={toggle}>Update Product</ModalHeader>
              <ModalBody>
                <UpdateProductForm
                  user={user}
                  products={products}
                  onSubmit={data => updateUserProduct(user._id, data)}
                  onSubmitSuccess={() => {
                    toggle();
                    addToast(`User's product updated`, 'success');
                  }}
                />
              </ModalBody>
            </>
          )}

          {type === FORM_PAUSE && (
            <>
              <ModalHeader toggle={toggle}>Pause Account</ModalHeader>
              <ModalBody>
                <PauseForm
                  user={user}
                  onSubmit={data => pauseAccount(user._id, data)}
                  onSubmitSuccess={() => {
                    toggle();
                    addToast('Account paused', 'success');
                  }}
                />
              </ModalBody>
            </>
          )}

          {type === FORM_REFERRAL && (
            <>
              <ModalHeader toggle={toggle}>Set referral</ModalHeader>
              <ModalBody>
                <ReferralForm
                  user={user}
                  searchUser={searchUser}
                  onSubmit={setReferrer}
                  onSuccess={() => {
                    toggle();
                    addToast('Referral saved', 'success');
                  }}
                />
              </ModalBody>
            </>
          )}

          {type === DUMB_PERIODS && (
            <>
              <ModalHeader toggle={toggle}>Update Dumb Period</ModalHeader>
              <ModalBody>
                <DumbPeriodForm
                  user={user}
                  onSubmit={data => updateDumbSubscription(user._id, data)}
                  onSuccess={() => {
                    toggle();
                    addToast('Dumb Period Updated', 'success');
                  }}
                  updateDumbSubscription={updateDumbSubscription}
                />
              </ModalBody>
            </>
          )}

          {type === FORM_SOCIAL_MEDIA && (
            <>
              <ModalHeader toggle={toggle}>Social media</ModalHeader>
              <ModalBody>
                <UpdateSocialMediaForm
                  user={user}
                  onSubmit={data => updateSocialMedia(user._id, data)}
                  onSubmitSuccess={() => {
                    toggle();
                    addToast('Social media updated', 'success');
                  }}
                />
              </ModalBody>
            </>
          )}

          {type === FORM_RE_OPEN_ACCOUNT && (
            <>
              <ModalHeader toggle={toggle}>
                <span>Re-Open Account</span>
              </ModalHeader>
              <ModalBody>
                <ReOpenForm
                  user={user}
                  onSubmit={data => reOpenAccount(user._id, data)}
                  onSubmitSuccess={e => {
                    if (e.status === 404 || e.status === 500) {
                      if (e.response.message) {
                        addToast(e.response.message, 'danger');
                      } else {
                        addToast('An error occurred', 'danger');
                      }
                    } else {
                      toggle();
                      addToast('Account re-opened', 'success');
                    }
                  }}
                />
              </ModalBody>
            </>
          )}

          {type === ATTRIBUTE_INFLUENCER && (
            <>
              <ModalHeader toggle={toggle}>Attributions VIP Booster Club</ModalHeader>
              <ModalBody>
                <AttributeInfluencerForm
                  user={user}
                  onSubmit={data => attributeInfluencer(user._id, data)}
                  onSubmitSuccess={() => {
                    toggle();
                    addToast('Micro-Influencer attributed', 'success');
                  }}
                />
              </ModalBody>
            </>
          )}

          {type === NEEDS_SUPPORT && (
            <>
              <ModalHeader toggle={toggle}>Needs support?</ModalHeader>
              <ModalBody>
                <NeedsSupportForm
                  user={user}
                  onSubmit={data => setNeedsSupport(user._id, data)}
                  onSubmitSuccess={() => {
                    toggle();
                    addToast('User updated', 'success');
                  }}
                />
              </ModalBody>
            </>
          )}

          {type === MANAGE_SCALE && (
            <>
              <ModalHeader toggle={toggle}>Manage scale</ModalHeader>
              <ModalBody>
                <ManageScaleForm
                  user={user}
                  getScale={getScale}
                  onSubmit={action => disconnectScale(user._id, action)}
                  onSubmitSuccess={result => {
                    toggle();
                    addToast('Scale updated', 'success');
                    setUser({ ...user, ...result });
                  }}
                />
              </ModalBody>
            </>
          )}

          {type === PETS_INVITE && (
            <>
              <ModalHeader toggle={toggle}>Pets Invite</ModalHeader>
              <ModalBody>
                <Form
                  initialValues={{ pilotCategory: PilotCategories.PetFood, product: null, variant: null }}
                  validationSchema={ProductSchema}
                  onSubmit={data => sendPilotInvite(user._id, data)}
                  onSuccess={toggle}
                >
                  {({ isSubmitting, dirty }) => (
                    <>
                      <Field
                        name="product"
                        type="select"
                        label="Add pet product"
                        options={createSelectOptions(
                          petProducts,
                          '--- select product ---',
                          item => `${item.vendor_name} - ${item.name}`
                        )}
                        required
                        onChange={event => {
                          selectPetProduct(event.target.value);
                        }}
                      />
                      {petProduct && (
                        <Field
                          name="variant"
                          type="select"
                          label="Select variant"
                          options={createSelectOptions(
                            petProduct.variants.filter(v => v.available),
                            '--- select variant ---',
                            getVariantSelectLabel
                          )}
                          required
                        />
                      )}
                      <SubmitButton color="dark" isSubmitting={isSubmitting} disabled={isSubmitting || !dirty}>
                        Send Pet Invite
                      </SubmitButton>
                    </>
                  )}
                </Form>
              </ModalBody>
            </>
          )}

          {type === HAF_INVITE && (
            <>
              <ModalHeader toggle={toggle}>Health and Fitness Invite</ModalHeader>
              <ModalBody>
                <Form
                  initialValues={{ pilotCategory: PilotCategories.HealthAndFitness, product: null, variant: null }}
                  validationSchema={ProductSchema}
                  onSubmit={data => sendPilotInvite(user._id, data)}
                  onSuccess={toggle}
                >
                  {({ isSubmitting, dirty }) => (
                    <>
                      <Field
                        name="product"
                        type="select"
                        label="Add health and fitness product"
                        options={createSelectOptions(
                          hafProducts,
                          '--- select product ---',
                          item => `${item.vendor_name} - ${item.name}`
                        )}
                        required
                        onChange={event => {
                          selectHafProduct(event.target.value);
                        }}
                      />
                      {hafProduct && (
                        <Field
                          name="variant"
                          type="select"
                          label="Select variant"
                          options={createSelectOptions(
                            hafProduct.variants.filter(v => v.available),
                            '--- select variant ---',
                            getVariantSelectLabel
                          )}
                          required
                        />
                      )}
                      <SubmitButton color="dark" isSubmitting={isSubmitting} disabled={isSubmitting || !dirty}>
                        Send Health and Fitness Invite
                      </SubmitButton>
                    </>
                  )}
                </Form>
              </ModalBody>
            </>
          )}
        </Modal>
      )}
    </Card>
  );
};

User.propTypes = {
  pricingRules: PropTypes.array.isRequired,
  addCredits: PropTypes.func.isRequired,
  createScaleShipment: PropTypes.func.isRequired,
  createMagicLink: PropTypes.func.isRequired,
  addMonths: PropTypes.func.isRequired,
  closeAccount: PropTypes.func.isRequired,
  hibernateAccount: PropTypes.func.isRequired,
  updateEmail: PropTypes.func.isRequired,
  updatePhone: PropTypes.func.isRequired,
  updateSocialMedia: PropTypes.func.isRequired,
  attributeInfluencer: PropTypes.func.isRequired,
  addToast: PropTypes.func.isRequired,
  giftOrderEmail: PropTypes.func.isRequired,
  updateGrind: PropTypes.func.isRequired,
  updatePricingRule: PropTypes.func.isRequired,
  pauseAccount: PropTypes.func.isRequired,
  searchUser: PropTypes.func.isRequired,
  setReferrer: PropTypes.func.isRequired,
  setNeedsSupport: PropTypes.func.isRequired,
  updateDumbSubscription: PropTypes.func.isRequired,
  options: PropTypes.object.isRequired,
  user: PropTypes.shape({
    _id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    vendor_id: PropTypes.shape({
      _id: PropTypes.string,
      name: PropTypes.string,
    }),
    base_id: PropTypes.string,
    base_tare: PropTypes.string,
    base_calibration: PropTypes.string,
    signupDate: PropTypes.string,
    dumb_period: PropTypes.number,
    trial: PropTypes.bool,
    landing_page: PropTypes.string,
    credits: PropTypes.array,
    referral_id: PropTypes.string.isRequired,
    status: PropTypes.string.isRequired,
    support: PropTypes.shape({
      order: PropTypes.shape({
        needs_support: PropTypes.bool,
        date: PropTypes.string,
      }),
      scale: PropTypes.shape({
        needs_support: PropTypes.bool,
        date: PropTypes.string,
      }),
    }),
    grind: PropTypes.object,
    paused: PropTypes.bool,
    pausedUntil: PropTypes.string,
    hibernate: PropTypes.bool,
    first_name: PropTypes.string.isRequired,
    last_name: PropTypes.string.isRequired,
    phone: PropTypes.string.isRequired,
    paidUntil: PropTypes.string,
    scale_status: PropTypes.string,
    pricing_rule: PropTypes.shape({
      _id: PropTypes.string.isRequired,
      free_shipping: PropTypes.bool,
      monthly_fee: PropTypes.number.isRequired,
      batch_size: PropTypes.number.isRequired,
      free_trial_length: PropTypes.number.isRequired,
      description: PropTypes.string.isRequired,
    }),
    product: PropTypes.object.isRequired,
    verifiedAddress: PropTypes.shape({
      street1: PropTypes.string.isRequired,
      street2: PropTypes.string,
      city: PropTypes.string.isRequired,
      state: PropTypes.string.isRequired,
      zip: PropTypes.string.isRequired,
    }),
    local: PropTypes.shape({
      email: PropTypes.string.isRequired,
    }).isRequired,
    scale_shipments: PropTypes.arrayOf(
      PropTypes.shape({
        _id: PropTypes.string.isRequired,
        status: PropTypes.string.isRequired,
      })
    ),
    personalized: PropTypes.shape({
      price_type: PropTypes.string,
    }),
    social_media: PropTypes.object,
    updateDumbSubscription: PropTypes.func,
  }).isRequired,
  disconnectScale: PropTypes.func.isRequired,
  getScale: PropTypes.func.isRequired,
  reOpenAccount: PropTypes.func.isRequired,
  updateAddress: PropTypes.func.isRequired,
  products: PropTypes.array,
  updateUserProduct: PropTypes.func.isRequired,
  getUserLastOrder: PropTypes.func.isRequired,
  sendPilotInvite: PropTypes.func.isRequired,
  scaleDevices: PropTypes.arrayOf(
    PropTypes.shape({
      _id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    })
  ).isRequired,
};

User.defaultProps = {
  products: [],
};
