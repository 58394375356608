import PropTypes from 'prop-types';
import { get } from 'lodash-es';
import * as qs from 'query-string';
import React, { useState } from 'react';
import { useEffect } from 'react';
import { connect } from 'react-redux';
import { Col, Row } from 'reactstrap';
import { Pagination } from '@bottomless/common/components';
import { useDataEffect } from '@bottomless/common/hooks';
import { addToastAction } from '@bottomless/common/store';
import { PanelPage } from '../../../../layouts/PanelPage/PanelPage';
import { getScaleByBaseIdAction, getScaleDeviceAction } from '../../../../store';
import { getProductOptionsAction } from '../../../../store/admin/product-options';
import { getProductsAction } from '../../../../store/admin/product';
import { sendPilotInviteAction } from '../../../../store/admin/pilot';
import {
  addCreditsAction,
  addMonthsAction,
  createMagicLinkAction,
  createScaleShipmentAction,
  getUsersAction,
  closeAccountAction,
  hibernateAccountAction,
  giftOrderEmailAction,
  updateGrindAction,
  updatePricingRuleAction,
  updateEmailAction,
  pauseUserAccountAction,
  searchUsersAction,
  setUserReferrerAction,
  updateSocialMediaAction,
  attributeInfluencerAction,
  updateUserDumbPeriodAction,
  reOpenAccountAction,
  setNeedsSupportAction,
  disconnectScaleAction,
  updateAddressAction,
  updateUserProductAction,
  updatePhoneAction,
  getUserLastOrderAction,
} from '../../../../store/admin/user';
import { getAllPricingRuleAction } from '../../../../store/pricing_rules';
import { Filters } from './components/Filters';
import { User } from './components/User';
import './Users.scss';

const UsersPageComponent = ({
  users,
  pricingRules,
  getUsers,
  getPricingRules,
  addToast,
  addCredits,
  addMonths,
  closeAccount,
  hibernateAccount,
  updateEmail,
  updateSocialMedia,
  attributeInfluencer,
  createScaleShipment,
  createMagicLink,
  giftOrderEmail,
  updateGrind,
  updatePricingRule,
  getProductOptions,
  isLoading,
  pauseAccount,
  searchUser,
  setReferrer,
  updateDumbSubscription,
  reOpenAccount,
  setNeedsSupport,
  disconnectScale,
  getScale,
  location: { search },
  updateAddress,
  getProducts,
  updateUserProduct,
  updatePhone,
  getUserLastOrder,
  sendPilotInvite,
  getScaleDevice,
}) => {
  const [options, setOptions] = useState(null);
  const [products, setProducts] = useState(null);
  const [scaleDevices, setScaleDevices] = useState([]);

  useEffect(() => {
    getPricingRules();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getUsers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  useDataEffect(getProductOptions, setOptions);
  useDataEffect(
    getProducts,
    response => {
      setProducts(get(response, 'docs', []));
    },
    { status: 'active', sort: 'name', dir: 1 }
  );

  useDataEffect(getScaleDevice, setScaleDevices);

  return (
    <PanelPage title="Users" className="page-admin-users">
      <Row className="mb-4">
        <Col>
          <Filters isLoading={isLoading} hasData={users && users.docs && users.docs.length > 0} />
        </Col>
      </Row>

      {users && pricingRules && options && (
        <>
          <div className="text-secondary text-sm text-right">
            {users.total} ({users.page}/{users.pages})
          </div>
          <Row>
            {users.docs.map(user => (
              <Col xs="12" key={user._id}>
                <User
                  pricingRules={pricingRules}
                  user={user}
                  addCredits={addCredits}
                  addToast={addToast}
                  createScaleShipment={createScaleShipment}
                  createMagicLink={createMagicLink}
                  addMonths={addMonths}
                  closeAccount={closeAccount}
                  reOpenAccount={reOpenAccount}
                  updateEmail={updateEmail}
                  updateSocialMedia={updateSocialMedia}
                  attributeInfluencer={attributeInfluencer}
                  giftOrderEmail={giftOrderEmail}
                  updateGrind={updateGrind}
                  updatePricingRule={updatePricingRule}
                  pauseAccount={pauseAccount}
                  searchUser={searchUser}
                  setReferrer={setReferrer}
                  options={options}
                  updateDumbSubscription={updateDumbSubscription}
                  setNeedsSupport={setNeedsSupport}
                  hibernateAccount={hibernateAccount}
                  disconnectScale={disconnectScale}
                  getScale={getScale}
                  updateAddress={updateAddress}
                  products={products}
                  updateUserProduct={updateUserProduct}
                  updatePhone={updatePhone}
                  getUserLastOrder={getUserLastOrder}
                  sendPilotInvite={sendPilotInvite}
                  scaleDevices={scaleDevices}
                />
              </Col>
            ))}
          </Row>
          <Pagination collection={users} onPageChange={getUsers} />
        </>
      )}
    </PanelPage>
  );
};

UsersPageComponent.propTypes = {
  getPricingRules: PropTypes.func.isRequired,
  getUsers: PropTypes.func.isRequired,
  addCredits: PropTypes.func.isRequired,
  createScaleShipment: PropTypes.func.isRequired,
  createMagicLink: PropTypes.func.isRequired,
  addMonths: PropTypes.func.isRequired,
  addToast: PropTypes.func.isRequired,
  closeAccount: PropTypes.func.isRequired,
  updateEmail: PropTypes.func.isRequired,
  updateSocialMedia: PropTypes.func.isRequired,
  attributeInfluencer: PropTypes.func.isRequired,
  giftOrderEmail: PropTypes.func.isRequired,
  updateGrind: PropTypes.func.isRequired,
  updatePricingRule: PropTypes.func.isRequired,
  getProductOptions: PropTypes.func.isRequired,
  pauseAccount: PropTypes.func.isRequired,
  setReferrer: PropTypes.func.isRequired,
  updateDumbSubscription: PropTypes.func.isRequired,
  setNeedsSupport: PropTypes.func.isRequired,
  users: PropTypes.shape({
    docs: PropTypes.array.isRequired,
    total: PropTypes.number.isRequired,
    limit: PropTypes.number.isRequired,
    page: PropTypes.number.isRequired,
    pages: PropTypes.number.isRequired,
  }),
  pricingRules: PropTypes.array.isRequired,
  isLoading: PropTypes.bool,
  location: PropTypes.shape({
    search: PropTypes.string,
  }).isRequired,
  searchUser: PropTypes.func.isRequired,
  disconnectScale: PropTypes.func.isRequired,
  getScale: PropTypes.func.isRequired,
  hibernateAccount: PropTypes.func.isRequired,
  reOpenAccount: PropTypes.func.isRequired,
  updateAddress: PropTypes.func.isRequired,
  getProducts: PropTypes.func.isRequired,
  updateUserProduct: PropTypes.func.isRequired,
  updatePhone: PropTypes.func.isRequired,
  getUserLastOrder: PropTypes.func.isRequired,
  sendPilotInvite: PropTypes.func.isRequired,
  getScaleDevice: PropTypes.func.isRequired,
};

export const UsersPage = connect(
  ({ adminUsers, adminPricingRules }) => ({
    users: adminUsers.data,
    isLoading: adminUsers.isLoading,
    pricingRules: adminPricingRules.data,
  }),
  (dispatch, { location: { search } }) => ({
    getPricingRules: () => dispatch(getAllPricingRuleAction()),
    getUsers: params => dispatch(getUsersAction({ ...params, ...qs.parse(search) })),
    searchUser: params => dispatch(searchUsersAction(params)),
    addCredits: (id, data) => dispatch(addCreditsAction(id, data)),
    createScaleShipment: (id, data) => dispatch(createScaleShipmentAction(id, data)),
    createMagicLink: (id, data) => dispatch(createMagicLinkAction(id, data)),
    addMonths: (id, data) => dispatch(addMonthsAction(id, data)),
    updateGrind: (id, data) => dispatch(updateGrindAction(id, data)),
    updatePricingRule: (id, data) => dispatch(updatePricingRuleAction(id, data)),
    addToast: (message, type) => dispatch(addToastAction(message, type)),
    closeAccount: data => dispatch(closeAccountAction(data)),
    hibernateAccount: (id, data) => dispatch(hibernateAccountAction(id, data)),
    updateEmail: (id, data) => dispatch(updateEmailAction(id, data)),
    updateSocialMedia: (id, data) => dispatch(updateSocialMediaAction(id, data)),
    attributeInfluencer: (id, data) => dispatch(attributeInfluencerAction(id, data)),
    giftOrderEmail: data => dispatch(giftOrderEmailAction(data)),
    getProductOptions: () => dispatch(getProductOptionsAction()),
    pauseAccount: (id, data) => dispatch(pauseUserAccountAction(id, data)),
    setReferrer: (id, data) => dispatch(setUserReferrerAction(id, data)),
    updateDumbSubscription: (id, data) => dispatch(updateUserDumbPeriodAction(id, data)),
    reOpenAccount: (id, data) => dispatch(reOpenAccountAction(id, data)),
    setNeedsSupport: (id, data) => dispatch(setNeedsSupportAction(id, data)),
    disconnectScale: (id, action) => dispatch(disconnectScaleAction(id, action)),
    getScale: baseId => dispatch(getScaleByBaseIdAction(baseId)),
    updateAddress: (id, data) => dispatch(updateAddressAction(id, data)),
    getProducts: params => dispatch(getProductsAction(params)),
    updateUserProduct: (id, data) => dispatch(updateUserProductAction(id, data)),
    updatePhone: (id, data) => dispatch(updatePhoneAction(id, data)),
    getUserLastOrder: id => dispatch(getUserLastOrderAction(id)),
    sendPilotInvite: (id, data) => dispatch(sendPilotInviteAction(id, data)),
    getScaleDevice: () => dispatch(getScaleDeviceAction()),
  })
)(UsersPageComponent);
